//export const API_BASE_URL = "http://selah.test/api";
export const API_BASE_URL = "https://stage-backend.selahcomfort.com/api";
export const AUTH_API = "/login";
export const AUTH_USER_API = "/get/user";
export const LOGOUT_API = "/logout";
export const BANNERS_API = "/get/banners/";
export const COUNTRY_API = "/get/countries";
export const CITY_API = "/get/cities";
export const COUNTRY_STATE_CITY_API = "/get/countryStateCity";
export const CATEGORIES_API = "/get/categories";
export const PROPERTIES_API = "/get/properties";
export const PROPERTY_API = "/get/property/";
export const RECOMMENDED_PROPERTIES_API = "/get/properties/recommended";
export const OUTSTANDING_PROPERTIES_API = "/get/properties/outstanding";
export const REGISTER_OWNER_API = "/post/customer/owner";
export const GET_TESTIMONIALS_API = "/get/testimonials";
export const CONTACT_API = "/post/contact";
export const SAVE_HGS_BOOKING_API = "/booking/hgs/save";
export const SAVE_CHN_BOOKING_API = "/booking/chn/save";
